#landing {
	background-color: #fcfcfc;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
h1 {
	letter-spacing: 4px;
	font-size: 4rem;
}
#url {
	width: 40%;
	height: 50px;
}
#actionBtn {
	margin: 25px;
}
.btnSpace {
	padding: 0 15px;
}
.myBtn {
	padding: 18px !important;
}
#input-url {
	width: 78.5%;
	margin-right: 1.5%;
}
#input-slug {
	width: 20%;
}

@media screen and (max-width: 900px) {
	#url {
		width: 60%;
	}
}

@media screen and (max-width: 600px) {
	h1 {
		font-size: 2.5rem;
		letter-spacing: 1px;
	}
	#url {
		width: 80%;
	}
	.btnSpace {
		padding: 0 3px;
	}
}

@media screen and (max-width: 400px) {
	h1 {
		font-size: 2rem;
	}
	.myBtn {
		padding: 10px !important;
	}
	.btnSpace {
		padding: 0;
	}
}

@media screen and (max-width: 300px) {
	.myBtn {
		padding: 8px !important;
	}
}
